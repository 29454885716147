
import {
  defineComponent,
  onUnmounted,
  onMounted,
  toRefs,
  ref,
  reactive,
  watch,
} from "vue";
import { menuController } from "@ionic/vue";
import { useRouter } from "vue-router";
import Calendar from "@/components/Calendar.vue";
import RoomBookCard from "@/components/meeting/RoomBookCard.vue";
import moment from "moment";
import SystemUtils from "@/utils/SystemUtils";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import { nextTick } from "process";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import Utils from "@/utils/Utils";
import { VisitorService } from "@/services/visitor/VisitorService";
export default defineComponent({
  name: "VisitorList",
  components: {
    RoomBookCard,
    CardLoading,
    Header,
    SearchBar,
  },
  props: {},
  ionViewWillEnter() {
    this.initData();
  },
  setup(props, { emit }) {
    const {} = toRefs(props);
    const router = useRouter();
    const btnIconRight = reactive({ data: ["iconleijigongshi"] });
    const btnSizeRight = ["34px"];
    let islist = ref(true); //是否显示加载中的标识

    const currentUser: any = SystemUtils.loginUser;
    const currentUserId = currentUser.id;
    const bookList = reactive({ data: new Array() });
    const searchNameText = ref("");
    let searchRef = ref(null);
    let searchParams = reactive({
      page: 1,
      size: 10,
      status: 0,
      userName: "",
    });
    const initData = () => {
      resetPage();
    };

    //重新从第一页获取
    const resetPage = () => {
      searchParams.page = 1;
      getListRequest((res: any) => {
        bookList.data = res;
      });
    };
    const nextPage = (event: any) => {
      searchParams.page++;
      getListRequest(
        (result: any) => {
          bookList.data = bookList.data.concat(result);
          setTimeout(() => {
            event.target.complete();
          }, 1000);
          if (result) {
            if (result == null || result.length <= 0) {
              //  event.target.disabled = true;
            }
          }
        },
        () => {
          event.target.complete();
        }
      );
    };
    const doRefresh = (event: any) => {
      resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 600);
    };
    const getListRequest = (callback: any, errCalback?: any) => {
      visitorService
        .getWaitVisitorList(searchParams)
        .then((res: any) => {
          islist.value = false;
          callback(res.rows);
        })
        .catch(() => {
          islist.value = false;
          if (errCalback) {
            errCalback();
          }
        });
    };
    //详情
    const detailHandler = (params: any) => {
      router.push({
        path: "/RoomBookDetail",
        query: {
          id: params.item.id,
        },
      });
    };
    const onFiltClick = () => {
      router.push({
        path: "/alreadyrecivervisitor",
      });
    };
    const addVisitor = () => {
      router.push({
        path: "/addvisitor",
      });
    };
    //简单搜索
    const searchHandler = (data: any) => {
      if (Utils.isInCludeEmoji(data.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      resetSearchParam();
      searchParams.userName = data.searchKey;
      searchNameText.value = data.searchKey;
      resetPage();
    };
    const resetSearchParam = () => {
      searchParams = {
        page: 1,
        size: 10,
        status: 0,
        userName: "",
      };
    };

    const bindReceptHandler = (id: string, type: string) => {
      router.push({
        path: "/visitordetails",
        query: {
          id: id,
          type: type,
        },
      });
    };

    onMounted(() => {
      //  initData();
    });
    onUnmounted(() => {});

    const visitorService = new VisitorService();
    let isMore = false;

    return {
      islist,
      router,
      bookList,
      searchParams,
      isMore,
      currentUserId,
      initData,
      nextPage,
      doRefresh,
      resetPage,
      detailHandler,
      btnIconRight,
      btnSizeRight,
      onFiltClick,
      searchNameText,
      searchHandler,
      searchRef,
      addVisitor,
      bindReceptHandler,
    };
  },
});
